<div class="w-full h-full min-h-screen bg-neutral-900">
    <div class="flex flex-col m-auto items-center justify-center h-screen space-y-2">
        <div class="flex flex-col items-center justify-center space-y-2">
            <h1 class="text-center text-5xl mx-auto font-bold text-palette-600 mt-12">Scan Ita Pdf Generator</h1>
            <p class="text-gray-300">Create PDFs from you favorite manga <a class="text-palette-500" href="https://scanita.org" target="_blank">ScanIta</a> scans.</p>
        </div>
        <div class="flex w-full m-auto pt-8">
            <app-input-form class="m-auto"></app-input-form>
        </div>
    </div> 
    
</div>