<footer class="flex flex-row text-neutral-200 bg-palette-600" id="footer-bg">
    <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
      <div class="flex title-font font-bold items-center md:justify-start justify-center text-white">
        <p>Scan Ita Extractor</p>
      </div>
      <div class="text-xs text-light sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-50 sm:py-2 sm:mt-0 mt-4 lg:text-sm">
        <span>{{ getCurrentDate()}} Scan Ita Extractor | Made with ❤️ by</span>
        <a
          aria-label="Sito internet Mattia Ricci"
          href="https://mattiaricci.it"
          class="hover:text-gray-300 cursor-pointer transition-std ml-0.5"
          target="_blank"
          rel="noopener noreferrer">
        Mattia Ricci
        </a>
      </div>
    </div>
  </footer>